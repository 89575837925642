/* global chrome */
import { useEffect, useState, useCallback, useMemo } from "react";
import { checkOS, OS, supportedMobileOSes } from "../System/System";
import { NativeClientApi } from "../API/XFA_NATIVE_CLIENT";
import {
  checkExtensionInstalled,
  getCredentialID,
  createCredential,
  getCredential,
  openExtension as extensionOpenExtension,
  checkDeviceAffiliatedWithOrganization,
  sendCompleteTransactionToExtension,
  sendInvitationsToExtension,
} from "./Extension";
import { Invitation } from "../Invitations/Invitation";
import {
  LoginBeginResponse,
  LoginFinishRequest,
  RegisterBeginResponse,
  RegisterFinishRequest,
} from "../API/XFA_DEVICE_API";

const useClientApi = (navigator: Navigator, browser: any) => {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState<
    boolean | undefined
  >();
  const [isClientAvailable, setIsClientAvailable] = useState<boolean>(false);
  const [isNativeClientAvailable, setIsNativeClientAvailable] =
    useState<boolean>(false);
  const [isProtocolHandlerAvailable, setIsProtocolHandlerAvailable] =
    useState<boolean>(false);
  const [checking, setChecking] = useState<boolean>(true);
  const [os, setOS] = useState<OS>();
  const [didCheckNativeClient, setDidCheckNativeClient] =
    useState<boolean>(false);
  const [useProtocolHandler, setUseProtocolHandler] = useState<boolean>(false);
  const [isRechecking, setIsRechecking] = useState<boolean>(false);
  const [silent, setSilent] = useState<boolean>(false);

  const deviceClient = useMemo(() => new NativeClientApi(), []);

  /**
   * Helper to invoke protocol handlers using an iframe.
   */
  const invokeProtocolHandler = async (url: string): Promise<boolean> => {
    try {
      return await new Promise((resolve) => {
        const iframe = document.createElement("iframe");
        iframe.style.display = "none";
        document.body.appendChild(iframe);

        const timeoutId = setTimeout(() => {
          document.body.removeChild(iframe);
          resolve(false);
        }, 2000);

        iframe.onload = () => {
          clearTimeout(timeoutId);
          document.body.removeChild(iframe);
          resolve(true);
        };

        iframe.src = url;
        handleRecheckClick();
      });
    } catch (error) {
      console.error("Protocol handler invocation failed", error);
      return false;
    }
  };

  const handleRecheckClick = () => {
    setIsRechecking(true);

    const checkClose = () => {
      setIsRechecking(false);
    };

    window.addEventListener("focus", checkClose, { once: true });
  };

  /**
   * Check if the native client is available.
   */
  const checkNativeClient = useCallback(async () => {
    try {
      console.log("Checking native client");
      const response = await deviceClient.default.getInstalled();
      console.log("Native client response", response);
      setIsNativeClientAvailable(response);
      setIsClientAvailable(
        response || isExtensionInstalled || isProtocolHandlerAvailable
      );
    } catch (error) {
      console.error("Error checking native client", error);
      setIsNativeClientAvailable(false);
      setIsClientAvailable(isExtensionInstalled || isProtocolHandlerAvailable);
    } finally {
      setChecking(false);
      setDidCheckNativeClient(true);
    }
  }, [deviceClient, isExtensionInstalled, isProtocolHandlerAvailable]);

  /**
   * Check if the protocol handler is available.
   */
  const checkProtocolHandler = useCallback(async () => {
    const protocolUrl = "xfatech://installed";
    const success = await invokeProtocolHandler(protocolUrl);
    setIsProtocolHandlerAvailable(success);
    setIsClientAvailable(success || isExtensionInstalled || false);
    setChecking(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExtensionInstalled]);

  /**
   * Runs checks for extension, native client, and protocol handler availability.
   */
  useEffect(() => {
    const runChecks = async () => {
      if (!os) return;

      if (supportedMobileOSes.includes(os)) {
        setIsExtensionInstalled(false);
        setIsNativeClientAvailable(false);
        setIsProtocolHandlerAvailable(false);
        setIsClientAvailable(false);
        setChecking(false);
        return;
      }

      if (!didCheckNativeClient && !isNativeClientAvailable) {
        await checkNativeClient();
        console.log("Native client checked");
      } else if (isExtensionInstalled === undefined) {
        checkExtensionInstalled(
          os,
          setChecking,
          setIsExtensionInstalled,
          navigator,
          browser as typeof chrome,
          window
        );
      } else if (
        !isExtensionInstalled &&
        !isNativeClientAvailable &&
        useProtocolHandler
      ) {
        await checkProtocolHandler();
        setUseProtocolHandler(false);
        console.log("Protocol handler checked");
      } else if (isExtensionInstalled) {
        setIsClientAvailable(true);
      }
      setChecking(false);
    };
    setChecking(true);
    runChecks();
  }, [
    os,
    navigator,
    browser,
    isExtensionInstalled,
    didCheckNativeClient,
    isNativeClientAvailable,
    isProtocolHandlerAvailable,
    useProtocolHandler,
    checkNativeClient,
    checkProtocolHandler,
  ]);

  /**
   * Rechecks the availability of extension, native client, and protocol handler.
   */
  const recheck = useCallback(
    (force: boolean, silent: boolean = false) => {
      if (!os) return;

      if (supportedMobileOSes.includes(os)) {
        setIsExtensionInstalled(false);
        setChecking(false);
        return;
      }

      setSilent(silent);
      setChecking(true);
      setIsExtensionInstalled(undefined);
      setDidCheckNativeClient(false);
      setUseProtocolHandler(force);

      checkNativeClient();
    },
    [os, checkNativeClient]
  );

  /**
   * Handles refocusing on the desktop to recheck availability.
   */
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        recheck(false);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [recheck]);

  /**
   * Initializes OS detection.
   */
  useEffect(() => {
    setOS(checkOS(navigator));
  }, [navigator]);

  /**
   * API methods
   */
  const clientApi = {
    async getCredentialID(): Promise<any> {
      if (isExtensionInstalled) {
        return new Promise((resolve, reject) => {
          try {
            getCredentialID(browser, navigator, resolve);
          } catch (error) {
            reject(error);
          }
        });
      }
      if (isNativeClientAvailable) {
        const response = await deviceClient.default.getAuthenticatorGetCredId();
        return response?.credId;
      }
      if (isProtocolHandlerAvailable) {
        const protocolUrl = "xfatech://authenticator-get-cred-id";
        await invokeProtocolHandler(protocolUrl);
        return { credId: "ProtocolHandlerCredId" };
      }
      throw new Error("No method available to get credential ID");
    },

    async openExtension(): Promise<void> {
      if (isExtensionInstalled) {
        extensionOpenExtension(browser, navigator);
      } else if (isNativeClientAvailable) {
        const protocolUrl = "xfatech://";
        window.location.href = protocolUrl;
      } else if (isProtocolHandlerAvailable) {
        const protocolUrl = "xfatech://open";
        await invokeProtocolHandler(protocolUrl);
      } else {
        throw new Error("No method available to open extension or client");
      }
    },

    async createCredential(
      body: RegisterBeginResponse
    ): Promise<RegisterFinishRequest> {
      if (isExtensionInstalled)
        return new Promise((resolve, reject) => {
          try {
            createCredential(browser, navigator, resolve, body);
          } catch (error) {
            reject(error);
          }
        });
      if (isNativeClientAvailable)
        return deviceClient.default.postAuthenticatorCreate(body);
      if (isProtocolHandlerAvailable) {
        const protocolUrl = `xfatech://authenticator-create?body=${encodeURIComponent(
          JSON.stringify(body)
        )}`;
        await invokeProtocolHandler(protocolUrl);
        return { credential: "ProtocolHandlerCredential" } as any;
      }
      throw new Error("No method available to create credential");
    },

    async getCredential(body: LoginBeginResponse): Promise<LoginFinishRequest> {
      if (isExtensionInstalled)
        return new Promise((resolve, reject) => {
          try {
            getCredential(browser, navigator, resolve, body);
          } catch (error) {
            reject(error);
          }
        });
      if (isNativeClientAvailable)
        return deviceClient.default.postAuthenticatorGet(body);
      if (isProtocolHandlerAvailable) {
        const protocolUrl = `xfatech://authenticator-get?body=${encodeURIComponent(
          JSON.stringify(body)
        )}`;
        await invokeProtocolHandler(protocolUrl);
        return { credential: "ProtocolHandlerLogin" } as any;
      }
      throw new Error("No method available to get credential");
    },

    async completeTransaction(transactionId: string): Promise<any> {
      if (isExtensionInstalled) {
        return new Promise((resolve, reject) => {
          try {
            sendCompleteTransactionToExtension(
              browser,
              navigator,
              transactionId,
              resolve
            );
          } catch (error) {
            reject(error);
          }
        });
      }
      if (isNativeClientAvailable) {
        try {
          const response = await deviceClient.default.putCompleteTransaction(
            transactionId,
            browser
          );
          return response;
        } catch (error) {
          console.error("Error completing transaction", error);
          throw error;
        }
      }
      if (isProtocolHandlerAvailable) {
        const protocolUrl = `xfatech://complete-transaction?transaction_id=${encodeURIComponent(
          transactionId
        )}`;
        await invokeProtocolHandler(protocolUrl);
        return { success: true };
      }
      throw new Error("No method available to complete transaction");
    },

    async checkDeviceAffiliatedWithOrganization(
      organizationId: string
    ): Promise<any> {
      if (isExtensionInstalled) {
        return new Promise((resolve, reject) => {
          try {
            checkDeviceAffiliatedWithOrganization(
              browser,
              navigator,
              organizationId,
              resolve
            );
          } catch (error) {
            reject(error);
          }
        });
      }
      if (isNativeClientAvailable) {
        const response = await deviceClient.default.getAffiliated(
          organizationId
        );
        return response?.affiliated === true;
      }
      if (isProtocolHandlerAvailable) {
        const protocolUrl = `xfatech://affiliated?organization_id=${encodeURIComponent(
          organizationId
        )}`;
        await invokeProtocolHandler(protocolUrl);
        return { affiliated: true };
      }
      throw new Error("No method available to check affiliation");
    },

    async sendInvitations(
      invitations: Invitation[],
      silent: boolean
    ): Promise<any> {
      if (isExtensionInstalled) {
        return new Promise((resolve, reject) => {
          try {
            sendInvitationsToExtension(
              browser,
              navigator,
              invitations,
              silent,
              resolve
            );
          } catch (error) {
            reject(error);
          }
        });
      }
      if (isNativeClientAvailable) {
        try {
          await Promise.all(
            invitations
              .filter(
                (invitation, index, self) =>
                  index === self.findIndex((i) => i.token === invitation.token)
              )
              .map((invitation) =>
                deviceClient.default.getInvitation(invitation.token)
              )
          );
          return { ok: true };
        } catch (error) {
          return { ok: false };
        }
      }
      if (isProtocolHandlerAvailable) {
        const protocolUrl = `xfatech://send-invitations?invitations=${encodeURIComponent(
          JSON.stringify(invitations)
        )}&silent=${silent}`;
        await invokeProtocolHandler(protocolUrl);
        return { success: true };
      }
      throw new Error("No method available to send invitations");
    },
  };

  return {
    clientApi,
    checking,
    isClientAvailable,
    isExtensionInstalled,
    isNativeClientAvailable,
    recheck,
    silent,
    isRechecking,
  };
};

export default useClientApi;
